.iubenda-tp-btn-login {
  z-index: 2147483647 !important;
  display: flex;
  position: fixed !important;
  right: 0 !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  width: 30px !important;
  height: 30px !important;
  background-color: #042C2B !important;
  color: #fff !important;
  border: none !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  cursor: pointer !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1) !important;
}

.iubenda-tp-btn {
  position: absolute;
  left: -9999px;
}

.iubenda-tp-btn-login:before {
  content: "🍪";
  font-size: 16px !important;
  display: block !important;
}

.iubenda-tp-btn-login:hover {
  opacity: 0.9 !important;
}

@media (max-width: 480px) {
  .iubenda-tp-btn-login {
    width: 35px !important;
    height: 30px !important;
    border-top-right-radius: 20% !important;
    border-bottom-right-radius: 20% !important;
    text-align: center;
    padding-left: 5px;
  }

  .iubenda-tp-btn-login:before {
    font-size: 16px !important;
  }
}

.iub-sr-only{
  display: none;
}